<template>
  <div>
    <Dashboard :wallet="$metamaskWallet" />
  </div>
</template>
  
<script>
import Dashboard from '@/components/dashboard/main.vue'

export default {
  components: { Dashboard },

}
</script>
  
  