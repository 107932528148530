<template>
    <div class="main">
        <div class="title">{{ $t('account.title') }}</div>
        <Nickname />

        <div class="description">{{ $t('account.description') }}</div>

        <div class="commission">
            <span class="titles">{{ $t('account.commission') }}</span>
            <span class="commission-count">{{ $donatrix.serviceFeeUI }}</span>
        </div>

        <div class="token">
            <span class="titles">{{ $t('account.token') }}</span>
            <span class="token-settings">
                <span class="show" @click="showToken">
                    <i class="show-icon" :class="{ 'hide-icon': tokenIsVisible }"></i>
                    {{ $t('account.show') }}
                </span>
                <span class="tokenLink" v-if="$account.account.accessToken && tokenIsVisible">
                    {{ $account.account.accessToken }}</span>
                <span class="reset" :activity="$account.generatingToken" :disabled="!canGenerateToken"
                    @click="GenerateAccessToken">
                    <i class="reset-icon"></i>
                    {{ $t('account.reset') }}
                </span>
            </span>
        </div>

        <div class="fiat">
            <span class="titles">{{ $t('account.fiat') }}</span>
            <span class="current-fiat" @click="openFiat">
                {{ fiat }}
                <i class="arrow"></i>
                <ul v-if="fiatOpen" class="fiat-dropdown">
                    <li class="fiat-dropdown__item" @click="selectFiat('USD')">USD</li>
                    <li class="fiat-dropdown__item" @click="selectFiat('EUR')">EUR</li>
                    <li class="fiat-dropdown__item" @click="selectFiat('RUB')">RUB</li>
                </ul>
            </span>
        </div>

        <div class="calendar">
            <span class="titles">{{ $t('account.calendar') }}</span>
            <span class="current-format" @click="openCalendar">
                <span v-if="this.local !== 'ru'">{{ calendar }}</span>
                <span v-if="this.local === 'ru'">{{ calendarRu }}</span>
                <i class="arrow"></i>
                <ul v-if="calendarOpen" class="calendar-dropdown">
                    <li class="calendar-dropdown__item" @click="selectCalendarFormat('Auto')">{{ $t('account.auto') }}</li>
                    <li class="calendar-dropdown__item" @click="selectCalendarFormat('MON')">{{ $t('account.mo') }}</li>
                    <li class="calendar-dropdown__item" @click="selectCalendarFormat('SUN')">{{ $t('account.sun') }}</li>
                    <li class="calendar-dropdown__item" @click="selectCalendarFormat('SAT')">{{ $t('account.sat') }}</li>
                </ul>
            </span>
        </div>

        <div class="buttons">
            <span class="cancel" :class="{ 'disabled': !accountSettings.dirty }" @click="cancel">{{ $t('buttons.cancel')
            }}</span>
            <span class="save btn" :class="{ 'disabled': !accountSettings.dirty }" @click="Save">
                <i class="save-icon"></i>
                <span>   {{ $t('buttons.save') }} </span>
            </span>
        </div>

        <Pop v-if="isSaving" :popText="popText" />
        <loader class="loader" v-if="$account.generatingToken" />
    </div>
</template>
<script>
import Nickname from "@/components/account/nickname.vue";
import { AccountSettings } from "../../code/classes/AccountSettings";
import Pop from '@/components/account/settingPop.vue';
import i18n from '../../i18n';
import loader from '@/components/dashboard/loader.vue';

export default {
    components: {
        Nickname,
        Pop,
        loader
    },

    props: {
        accountSettings: { type: AccountSettings },
    },

    data: () => ({
        fiat: 'USD',
        calendar: 'Auto',
        calendarRu: 'Авто',
        fiatOpen: false,
        calendarOpen: false,
        tokenIsVisible: false,
        isSaving: false,
        popText: i18n.t('donationSettings.success')
    }),

    mounted() {
        this.userName = localStorage.getItem('userName') || '';
        this.fiat = this.$store.state.newFiat == '' ? this.accountSettings?.displayCurrencySetting?.actualValue?.toUpperCase() : this.$store.state.newFiat
        this.calendar = this.$store.state.newCalendarFormat == '' ? this.accountSettings?.calendarFormatSetting?.actualValue?.toUpperCase() : this.$store.state.newCalendarFormat

        if (this.local === 'ru') {
            if (this.calendar === 'Auto') {
                this.calendarRu = 'Авто'
            }
            if (this.calendar === 'SUN') {
                this.calendarRu = 'ВС'
            }
            if (this.calendar === 'SAT') {
                this.calendarRu = 'CУБ'
            }
            if (this.calendar === 'MON') {
                this.calendarRu = 'ПН'
            }
        }
    },

    watch: {
        fiatOpen: function (value) {
            if (value) {
                document.addEventListener('click', event => {
                    if ((!document.querySelector('.current-fiat')?.contains(event.target))) {
                        this.fiatOpen = false
                    }
                });
            }
        },

        calendarOpen: function (value) {
            if (value) {
                document.addEventListener('click', event => {
                    if ((!document.querySelector('.current-format')?.contains(event.target))) {
                        this.calendarOpen = false
                    }
                });
            }
        },
    },

    computed: {
        canGenerateToken() {
            return !this.$account.disableTokenGenerator && !this.$account.generatingToken;
        },

        local() {
            return localStorage?.getItem('lang')
        }
    },

    methods: {
        showToken() {
            if (!this.tokenIsVisible) {
                this.tokenIsVisible = true
            } else {
                this.tokenIsVisible = false
            }
        },

        GenerateAccessToken() {
            this.$account.GenerateAccessToken();
        },
        openFiat() {
            if (!this.fiatOpen) {
                this.fiatOpen = true
            } else {
                this.fiatOpen = false
            }
        },

        openCalendar() {
            if (!this.calendarOpen) {
                this.calendarOpen = true
            } else {
                this.calendarOpen = false
            }
        },

        selectFiat(value) {
            this.fiat = value
            this.accountSettings.displayCurrencySetting.pendingValue = value?.toLowerCase()
        },

        selectCalendarFormat(value) {
            this.calendar = value
            if (this.local === 'ru') {
                if (value === 'Auto') {
                    this.calendarRu = 'Авто'
                }
                if (value === 'SUN') {
                    this.calendarRu = 'ВС'
                }
                if (value === 'SAT') {
                    this.calendarRu = 'CУБ'
                }
                if (value === 'MON') {
                    this.calendarRu = 'ПН'
                }
            }
            this.accountSettings.calendarFormatSetting.pendingValue = value?.toLowerCase()
        },

        cancel() {
            this.userName = ''
            this.readonly = true
            this.fiat = this.$store.state.newFiat,
                this.calendar = this.$store.state.newCalendarFormat,
                this.accountSettings.Revert()
        },

        async Save() {
            if (this.isSaving) return;
            this.isSaving = true;
            this.accountSettings
                .Save()
                .then(() => {
                    setTimeout(() => {
                        this.isSaving = false;
                    }, 1000)

                    localStorage.setItem('fiat', this.fiat);
                    this.$store.commit('newFiat');

                    localStorage.setItem('calendarFormat', this.calendar);
                    this.$store.commit('newCalendarFormat');
                })
                .catch((error) => {
                    console.error(error);
                    setTimeout(() => {
                        this.isSaving = false;
                    }, 1000)
                });
        },
    }

}
</script>
<style scoped lang="scss">
.main {
    position: relative;
    border-radius: 14px;
    background: #130E25;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 560px;
    padding: 40px 24px;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}

.title {
    margin-bottom: 24px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.titles {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.description {
    margin-top: 12px;
    margin-bottom: 24px;
    color: #A2A2A2;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.commission {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
}

.commission .titles {
    max-width: 119px;
}

.commission-count {
    margin-left: 9px;
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.token {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}

.token-settings {
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.tokenLink {
    margin-left: 24px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.show,
.reset {
    display: flex;
    align-items: center;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-decoration-line: underline;
    cursor: pointer;
}

.show {
    margin-left: 30px;
}

.reset {
    margin-left: 24px;
}

.show-icon {
    width: 18px;
    height: 12px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='14' viewBox='0 0 20 14' fill='none'%3E%3Cpath d='M1.20057 7.78442C0.933139 7.2954 0.933144 6.70445 1.20058 6.21544C2.9 3.10803 6.20336 1 9.99997 1C13.7966 1 17.1 3.10809 18.7994 6.21558C19.0669 6.7046 19.0669 7.29555 18.7994 7.78456C17.1 10.892 13.7966 13 10 13C6.20336 13 2.89997 10.8919 1.20057 7.78442Z' stroke='url(%23paint0_linear_2012_8027)'/%3E%3Cpath d='M7 7C7 8.65685 8.34315 10 10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4' stroke='url(%23paint1_linear_2012_8027)' stroke-linecap='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2012_8027' x1='1.18208' y1='7.8885' x2='15.8006' y2='7.8885' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_2012_8027' x1='7.06069' y1='7.44425' x2='11.9335' y2='7.44425' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.hide-icon {
    width: 18px;
    height: 12px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.0521 5.55643C1.31144 5.34514 1.69677 5.37953 1.91276 5.63323C3.34066 7.3105 5.9307 9.40347 9.9987 9.40347C14.0666 9.40347 16.6566 7.31052 18.0845 5.63324C18.3005 5.37954 18.6859 5.34516 18.9452 5.55644C19.2045 5.76774 19.2397 6.14469 19.0237 6.39839C18.5834 6.91566 18.0378 7.47353 17.3801 8.00844C17.4155 8.04716 17.4462 8.09105 17.4711 8.13972L19.1007 11.3281C19.2516 11.6234 19.1293 11.9825 18.8274 12.1301C18.5255 12.2778 18.1584 12.1581 18.0075 11.8628L16.4034 8.72414C15.3864 9.39205 14.1639 9.96714 12.7196 10.2984L13.4497 13.8695C13.5159 14.1932 13.3013 14.5081 12.9703 14.5729C12.6394 14.6376 12.3174 14.4277 12.2512 14.104L11.5161 10.5085C11.0322 10.5674 10.5265 10.5991 9.9987 10.5991C9.47078 10.5991 8.96512 10.5674 8.48112 10.5085L7.74607 14.104C7.67987 14.4277 7.35792 14.6376 7.02697 14.5729C6.69602 14.5081 6.4814 14.1932 6.54758 13.8695L7.27768 10.2983C6.08607 10.0251 5.04553 9.5858 4.14677 9.06539L1.93206 11.9541C1.72956 12.2182 1.34651 12.2718 1.07651 12.0737C0.806506 11.8756 0.751785 11.5009 0.95429 11.2367L3.12787 8.40164C2.23686 7.75303 1.52328 7.04407 0.97359 6.39838C0.757603 6.14467 0.792753 5.76772 1.0521 5.55643Z' fill='url(%23paint0_linear_2083_9659)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2083_9659' x1='1.01748' y1='10.68' x2='15.9067' y2='10.68' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.reset-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M8.74709 13.5556H4.36542V18M12.2524 6.44444H16.6341V2M4 7.33636C4.49135 6.10281 5.314 5.03381 6.37446 4.25087C7.43492 3.46792 8.69175 3.00224 10 2.90712C11.3083 2.812 12.6159 3.09102 13.7756 3.71254C14.9353 4.33406 15.899 5.27337 16.5588 6.42322M17 12.6641C16.5087 13.8976 15.686 14.9666 14.6255 15.7496C13.5651 16.5325 12.3095 16.9976 11.0013 17.0927C9.69297 17.1878 8.38434 16.9088 7.22463 16.2873C6.06492 15.6658 5.10051 14.7267 4.44073 13.5768' stroke='url(%23paint0_linear_1920_13812)' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1920_13812' x1='4.1315' y1='11.1847' x2='14.6893' y2='11.1847' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.fiat {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}

.current-fiat {
    position: relative;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    padding: 14px 34.4px;
    min-width: 49.2px;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.10);
    cursor: pointer;
}

.arrow {
    width: 13px;
    height: 7px;
    margin-left: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'%3E%3Cpath d='M13 1.5L7 6.5L1 1.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.calendar {
    display: flex;
    align-items: center;
}

.calendar .titles,
.token .titles,
.fiat .titles {
    max-width: 98px;
    width: 98px;
}

.current-format {
    position: relative;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 52.14px;
    padding: 14px 32.93px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.10);
    color: #FFF;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    cursor: pointer;
}

.fiat-dropdown {
    position: absolute;
    top: 48px;
    right: 3px;
    width: 71px;
    height: max-content;
    padding: 21px 0px 13px;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='71' height='86' viewBox='0 0 71 86' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M36.7744 1.62418C35.9517 -0.541389 34.618 -0.541389 33.7953 1.62418L32.9492 3.74281C32.2234 5.55988 31.8605 6.46841 31.2537 7.13672C30.7175 7.72724 30.0478 8.18091 29.3005 8.45984C28.4548 8.77551 27.4764 8.77551 25.5198 8.77551H8.27436C5.39718 8.77551 4.35385 9.38645 3.302 10.5337C2.25015 11.6809 1.42465 13.3644 0.86211 15.5095C0.299574 17.6547 0 19.7824 0 25.6501V69.1255C0 74.9931 0.299574 77.1209 0.86211 79.266C1.42465 81.4111 2.25015 83.0946 3.302 84.2418C4.35385 85.3891 5.39718 86 8.27436 86H62.7256C65.6028 86 66.6461 85.3891 67.698 84.2418C68.7499 83.0946 69.5754 81.4111 70.1379 79.266C70.7004 77.1209 71 74.9931 71 69.1255V25.6501C71 19.7824 70.7004 17.6547 70.1379 15.5095C69.5754 13.3644 68.7499 11.6809 67.698 10.5337C66.6461 9.38645 65.6028 8.77551 62.7256 8.77551H47.3312C46.4419 8.77551 45.9973 8.77551 45.5912 8.74237C42.0989 8.45734 39.0106 6.36525 37.4505 3.22778C37.2691 2.86298 37.1042 2.45005 36.7744 1.62418Z' fill='%2341385B'/%3E%3C/svg%3E");
}

.calendar-dropdown {
    position: absolute;
    top: 48px;
    right: 3px;
    width: 71px;
    height: max-content;
    padding: 21px 0px 8px;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='71' height='101' viewBox='0 0 71 101' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M36.7744 1.90748C35.9517 -0.635811 34.618 -0.635811 33.7953 1.90748L32.7838 4.88193C32.1201 6.83329 31.7883 7.80897 31.1802 8.52993C30.6431 9.16662 29.9555 9.65906 29.1798 9.96251C28.3015 10.3061 27.2709 10.3061 25.2098 10.3061H8.27436C5.39718 10.3061 4.35385 11.0236 3.302 12.3709C2.25015 13.7183 1.42465 15.6954 0.86211 18.2147C0.299574 20.734 0 23.2328 0 30.1239V81.1822C0 88.0733 0.299574 90.5722 0.86211 93.0914C1.42465 95.6107 2.25015 97.5879 3.302 98.9352C4.35385 100.282 5.39718 101 8.27436 101H62.7256C65.6028 101 66.6461 100.282 67.698 98.9352C68.7499 97.5879 69.5754 95.6107 70.1379 93.0914C70.7004 90.5722 71 88.0733 71 81.1822V30.1239C71 23.2328 70.7004 20.734 70.1379 18.2147C69.5754 15.6954 68.7499 13.7183 67.698 12.3709C66.6461 11.0236 65.6028 10.3061 62.7256 10.3061H48.5017C46.8667 10.3061 46.0491 10.3061 45.3142 10.1963C42.135 9.72119 39.378 7.74675 37.9046 4.88983C37.564 4.2294 37.3008 3.45542 36.7744 1.90748Z' fill='%2341385B'/%3E%3C/svg%3E");
}

.fiat-dropdown__item,
.calendar-dropdown {
    color: #FFF;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    cursor: pointer;
}

.fiat-dropdown__item:not(:last-child),
.calendar-dropdown__item:not(:last-child) {
    margin-bottom: 8px;
}

.buttons {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cancel {
    margin-right: 23px;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    padding: 14px 44.5px;
    border-radius: 41px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background: #0D0B17;
}

.cancel:hover {
    transition: .3s;
    background: rgba(255, 255, 255, 0.25);
}

.save {
    cursor: pointer;
    padding: 15px 44px 15px 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
}

.save-icon {
    width: 16px;
    height: 16px;
    margin-right: 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M2 8.00002L6.00028 12L14 4' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

@media (min-width:1800px) {

    .titles,
    .show,
    .reset,
    .current-fiat,
    .current-format {
        font-size: 16px;
    }

    .main {
        max-width: 573px;
    }

    .title {
        margin-bottom: 22px;
    }

    .commission {
        margin-bottom: 21px;
    }

    .commission-count {
        margin-left: 22px;
    }

    .token-settings {
        margin-top: 2px;
    }

    .current-fiat {
        padding: 14px 64px;
    }

    .current-format {
        padding: 14px 62px;
    }

    .buttons {
        margin-top: 56px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .title {
        font-size: 16px;
    }

    .main {
        max-width: 556px;
        padding: 40px 30px;
        margin-top: 14px;
    }

    .reset {
        margin-left: 51px;
    }

    .buttons {
        margin-top: 39px;
    }

    .cancel {
        margin-right: 25px;
        font-size: 14px;
        padding: 14px 48px;
    }

    .save {
        padding: 15px 40px;
    }
}

@media (max-width: 680px) {
    .title {
        font-size: 14px;
    }

    .titles,
    .show,
    .reset {
        font-size: 12px;
    }

    .main {
        padding: 34px 20px;
        max-width: 260px;
    }

    .description {
        font-size: 10px;
        margin-top: 11px;
        margin-bottom: 23px;
    }

    .commission {
        margin-bottom: 23px;
    }

    .commission .titles {
        max-width: max-content;
    }

    .commission-count {
        margin-left: 8px;
    }

    .buttons {
        flex-direction: column;
    }

    .token {
        align-items: flex-start;
        flex-direction: column;
    }

    .token-settings {
        margin-top: 11px;
        flex-wrap: wrap;
    }

    .show {
        margin-left: 0;
        order: 1;
    }

    .show-icon {
        width: 19px;
        height: 13px;
        margin-right: 11px;
    }

    .tokenLink {
        order: 3;
        margin-left: 0;
        margin-top: 14px;
    }

    .reset {
        margin-left: 25px;
        order: 2;
    }

    .reset-icon {
        margin-right: 9px;
    }

    .current-fiat {
        margin-left: 71px;
    }

    .current-format {
        margin-left: 46px;
    }

    .buttons {
        flex-direction: column;
        align-items: stretch;
    }

    .cancel {
        margin-right: 0;
        margin-bottom: 14px;
        padding: 12px 48px;
    }

    .save {
        padding: 12px 40px;
    }
}
</style>
