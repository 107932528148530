<template>
    <div v-if="enabled">
        <div class="account-name">
            <span class="titles">{{ $t('account.nickname') }}</span>
            <input class="username" ref="input" :placeholder="placeholder" v-model="nickname" v-on:keyup.enter="action"
                v-on:keyup.esc="onEsc" @input="onChange" />
            <span :activity="pending" class="edit" @click="action">{{ unique ? $t('account.edit') : $t('account.verify') }}</span>
            <loader class="loader" v-if="pending" />
        </div>
    </div>
</template>
  
<script>
import { nextTick } from "vue";
import loader from '@/components/dashboard/loader.vue';
import i18n from '../../i18n';

export default {
    data() {
        return {
            unique: false,
            pending: false,
            nickname: undefined,
            canChange: false,
            canChangeIn: undefined,
        };
    },
    components: { loader },

    computed: {
        enabled() {
            return this.$firebase.account !== undefined;
        },

        placeholder() {
            return this?.$account?.public?.nickname || i18n.t('account.placeholder')
        }
    },
    created() {
        this.H = setInterval(() => {
            if (!this.$account.public.nickname || !this.$account.public.nicknameUpdatedAt) {
                this.canChange = true;
                return;
            }
            const updatedAt = new Date(this.$account.public.nicknameUpdatedAt);
            const renameDelaySeconds = this.$donatrix.settings.renameDelaySeconds;
            const canUpdateAt = new Date(updatedAt.getTime() + renameDelaySeconds * 1000);
            const utcNow = new Date();
            this.canChange = utcNow >= canUpdateAt;
            if (this.canChange) {
                this.canChangeIn = undefined;
            } else {
                const seconds = this.canChange ? 0 : ((canUpdateAt - utcNow) / 1000).toFixed(0);
                this.canChangeIn = new Date(seconds * 1000).toISOString().slice(11, 19);
            }
        }, 100);
    },

    unmounted() {
        clearInterval(this.H);
    },

    methods: {
        onFiltered(value) {
            this.nickname = value;
        },
        onEsc() {
            this.$refs.input.blur();
        },
        onChange() {
            this.unique = false;
        },
        verify() {
            if (this.pending) return;
            this.pending = true;

            this.unique = false;
            this.$account
                .testNicknameIsUnique(this.nickname, this.onFiltered.bind(this))
                .then(() => {
                    this.pending = false;
                    this.unique = true;
                    nextTick(() => {
                        this.$refs.input.focus();
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.pending = false;
                    nextTick(() => {
                        this.$refs.input.focus();
                    });
                });
        },
        change() {
            if (this.pending) return;
            this.pending = true;

            this.$account
                .changeNickname(this.nickname)
                .then(() => {
                    this.nickname = "";
                    this.unique = false;
                    this.pending = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.unique = false;
                    this.pending = false;
                });
        },
        action() {
            this.$refs.input.blur();
            if (!this.unique) this.verify();
            else this.change();
        },
    },
};
</script>
  
<style scoped lang="scss">
.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
}

.loader {
    top: 0;
}

.titles {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.account-name {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.username {
    padding: 15px 24px;
    width: 330px;
    border-radius: 16px;
    border: none;
    background: rgba(255, 255, 255, 0.10);
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.username::placeholder {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.edit {
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-decoration-line: underline;
    cursor: pointer;
}

@media (min-width:1800px) {

    .titles,
    .username,
    .username::placeholder,
    .edit {
        font-size: 16px;
    }
}

@media (max-width: 680px) {

    .titles,
    .username,
    .username::placeholder,
    .edit {
        font-size: 12px;
    }

    .account-name {
        flex-direction: column;
        align-items: flex-start;
    }

    .username {
        width: 218px;
        margin-top: 10px;
        margin-bottom: 12px;
        padding: 14px 20px;
    }
}
</style>
  