<template>
    <div>
        <div class="chart" ref="chart"></div>
    </div>
</template>
  
<script>
import * as echarts from 'echarts';

export default {
    props: ['choosenPeriod', 'choosenId', 'data'],


    watch: {
        "$firebase.account"() {
            this.createChart();
        },

        "$donatrix.settingsReady"() {
            this.createChart();
        },

    },

    data() {
        return {
            chart: null
        };
    },

    mounted() {
        this.chart = echarts.init(this.$refs.chart);
        this.createChart();
        window.addEventListener('resize', this.handleResize);
    },


    methods: {

        createChart() {
            const chartContainer = this.$refs.chart;
            const chart = echarts.init(chartContainer);

            const options = {
                grid: {
                    containLabel: true,
                    left: '2%',
                    right: '5%',
                    top: '2%',
                    bottom: '2%'
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    borderColor: 'rgba(0,0,0,0.7)',
                    textStyle: {
                        color: '#fff'
                    },
                    formatter: `{b0}<br/>{c0} ${this.$store?.state?.newFiat == 'USD' ? 'USD' : this.$store?.state?.newFiat}`
                },

                xAxis: {
                    type: 'category',
                    data: this.choosenPeriod,
                    boundaryGap: false,

                    axisTick: {
                        show: false
                    },
                    
                    axisLabel: {
                        textStyle: {
                            color: '#CBD5E0',
                            fontSize: 12,
                            fontStyle: 'normal',
                            fontFamily: 'Gilroy-Medium'
                        }
                    },
                },
                yAxis: {
                    type: 'value',

                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#56577A',
                            width: 2,
                            type: 'dashed'
                        }
                    },

                    axisLabel: {
                        textStyle: {
                            color: '#CBD5E0',
                            fontSize: 12,
                            fontStyle: 'normal',
                            fontFamily: 'Gilroy-Medium'
                        }
                    }
                },
                series: [
                    {
                        data: this.data,
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: '#B59BFF'
                        },
                        lineStyle: {
                            width: 4,
                            shadowColor: 'rgba(0,0,0,0.3)',
                            color: '#CCADFF',
                            shadowBlur: 10,
                            shadowOffsetY: 8
                        },
                        symbol: 'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: 'transparent',
                            borderColor: 'transparent',
                            borderWidth: 3
                        },
                        animation: true, // Включение анимации для точек
                        animationDuration: 1000, // Длительность анимации в миллисекундах
                        animationEasing: 'linear',

                        emphasis: {
                            symbolSize: 10,
                            itemStyle: {
                                color: 'blue',
                                borderColor: 'white',
                                borderWidth: 3
                            }
                        }
                    },

                ],
            };

            chart.setOption(options);
        },

        handleResize() {
            this.chart.resize();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    width: 100%;
    height: 392px;
}

@media (min-width:1800px) {
    .chart {
        height: 500px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .chart {
        height: 341px;
    }
}

@media (max-width: 680px) {
    .chart {
        height: 241px;
    }
}
</style>