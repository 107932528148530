<template>
    <div class="statistic">
        <Daynamic :accountSettings="$account.accountSettings" />
        <Messages />
    </div>
</template>
<script>
import Daynamic from '@/components/dashboard/daynamic.vue';
import Messages from '@/components/dashboard/messages.vue';
import { AccountSettings } from "../../code/classes/AccountSettings";

export default {
    components : { Daynamic, Messages},
    props: {
        accountSettings: { type: AccountSettings },
    },
}
</script>
<style scoped lang="scss">
.statistic {
    display: flex;
}

@media (min-width: 0px) and (max-width: 1290px) {
    .statistic {
        flex-direction: column;
    }
}
</style>