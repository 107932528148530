<template>
    <ul class="breadcrumbs">
        <li class="breadcrumbs-item">
            <router-link to="/dashboard" class="link">{{ $t('breadcrumbs.pages') }}</router-link>
        </li>
        <li class="breadcrumbs-item separator">/</li>
        <li class="breadcrumbs-item">
            {{ this.$route.meta.breadcrumb }}
        </li>
    </ul>
</template>
<script>
export default {
}
</script>
<style scoped lang="scss">
.breadcrumbs {
    margin-left: 24px;
    display: flex;
}

.breadcrumbs-item {
    margin-bottom: 22px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.link {
    color: #A0AEC0;
}

.separator {
    margin-left: 7px;
    margin-right: 6px;
}

@media (min-width: 0px) and (max-width: 1290px) {
    .breadcrumbs {
        margin-left: 31px;
    }

    .breadcrumbs-item {
        margin-bottom: 16px;
    }
}

@media (max-width: 680px) {
    .breadcrumbs {
        margin-left: 12px;
    }

    .breadcrumbs-item {
        margin-bottom: 25px;
    }
}
</style>